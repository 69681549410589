@import '../../../styles/variables.module.scss';
.cookiesBlock {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.helpTitle {
  @include headline-2();
}
.helpParagraph {
  @include running_line();
  color: $gray-20;
  margin-top: 2.4rem;
}

@import '../../styles/variables.module.scss';

.helpPage {
  padding-inline: 8rem;
  margin-top: 2.4rem;
  margin-bottom: 40rem;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .helpPage {
    padding-inline: 3.2rem;
  }
}
@media (max-width: 767px) {
  .helpPage {
    padding-inline: 3.2rem;
  }
}

@import "../../../../styles/variables.module";
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderIconSmall {
  width: 3.2rem;
  height: 3.2rem;
  animation: rotation 1.2s linear infinite;
}


@use 'sass:math';
$headerHeight: 8.6rem;
$max-width: 144rem;
$min-width: 112rem;
$form-width: 40.9rem;
$extra-small: 6.4rem;
$small: 19rem;
$medium: 41.1rem;
$large: 63rem;

$xxl-spacing: 3.2rem;
$xl-spacing: 2.4rem;
$l-spacing: 1.6rem;
$m-spacing: 1.2rem;
$s-spacing: 0.8rem;
$xs-spacing: 0.4rem;

$white-color: #ffffff;
$black-color: #040404;
$accent_4: #3a7f66;
$header-text-color: #212a2f;
$second-color: #f8f8f8;
$active-color: #ad1f00;
$bg-color: #d9d9d9;
$section-bg-color: #f1f1f1;
$btn-bg-color: #6d6d6d;
$transparent-30: rgba(169, 223, 203, 0.3);

@mixin headline-1() {
  font-weight: 800;
  font-size: 56px;
  line-height: 150%;
  letter-spacing: 2.24px;
  text-transform: uppercase;
}
@mixin tablet-headline-1() {
  font-weight: 800;
  font-size: 48px;
  text-transform: uppercase;
  line-height: 120%;
}
@mixin headline-2() {
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tablet-headline-2() {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin headline-3() {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tablet-headline-3() {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tablet-headline-32() {
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin headline-4() {
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tablet-headline-4() {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin headline-5() {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tablet-headline-5() {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
}
@mixin headline-6() {
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin headline-7() {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin headline-8() {
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin headline-9() {
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin headline-10() {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin headline-11() {
  font-weight: 700;
  font-size: 8px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin headline-12() {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
}
@mixin body-1() {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}
@mixin body-2() {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
}
@mixin body-3() {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
@mixin body-4() {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}
@mixin body-5() {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}
@mixin menu-1() {
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin menu-2() {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin menu-3() {
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
}
@mixin menu-4() {
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
}
@mixin menu-5() {
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
}
@mixin link-1() {
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  text-decoration: underline;
}
@mixin link-2() {
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
}
@mixin link-3() {
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
}
@mixin buttonFont() {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
}
@mixin button-s() {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}
@mixin running-line() {
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  text-transform: uppercase;
}
@mixin tab-bars() {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
}
@mixin breadcrumb() {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-transform: none;
}
@mixin discount-price() {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
}
@mixin label() {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
@mixin input-text() {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

$accent-a-95: #030a07;
$accent-a-9: #05140f;
$accent-a-85: #081e16;
$accent-a-8: #0a281d;
$accent-a-7: #03281c;
$accent-a-6: #15503a;
$accent-a-5: #1a6449;
$accent-a-4: #3a7f66;
$accent-a-3: #5a9b83;
$accent-a-2: #79b6a0;
$accent-a-15: #89c4ae;
$accent-a-1: #99d2bd;
$accent-a-05: #a9dfcb;
$accent-a-0: #b9edda;

$gray-10: #4a4a4a;
$gray-20: #5d5d5d;
$gray-30: #727272;
$gray-40: #868686;
$gray-50: #9b9b9b;
$gray-60: #afafaf;
$gray-70: #c3c3c3;
$gray-80: #d7d7d7;
$gray-90: #f5f5f5;

$black: #000000;
$white: #fafafa;
$transparent70-gray10: rgba(74, 74, 74, 0.7);
$transparent-30-a-05: rgba(169, 223, 203, 0.3);

$success: #2ab66a;
$error: #ea001b;
$warning: #ffd90f;
$additional-information: #589bff;
$light-pink: #dfc5c5;


@mixin svgBaseStyle(){
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin divider() {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;

  div {
    width: 50%;
    height: 0.1rem;
    background: $gray-80;
  }
  span {
    color: $black;
    @include body-3();
  }
}

@mixin form() {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  p {
    @include body-3();
    span {
      @include link-1();
      @include link();
    }
  }
}
@mixin form-mobile() {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  p {
    @include body-4();
    justify-self: center;
    span {
      @include link-1();
      @include link();
    }
  }
}

@mixin form-expected-error {
  width: $form-width;
  display: flex;
  padding: 1.2rem;
  align-items: center;
  align-self: stretch;
  background: $light-pink;

  span {
    @include body-3();
    color: $error;
  }
}

@mixin form-title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  p:first-child {
    @include headline-2();
  }

  p:last-child {
    @include body-2();
    color: $gray-10;
  }
}
@mixin form-title-block-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  p:first-child {
    @include tablet-headline-2();
  }

  p:last-child {
    @include body-3();
    color: $gray-10;
  }
}
@mixin form-message-block($background-color, $text-color) {
  display: flex;
  align-self: stretch;
  background: $background-color;
  p {
    width: 100%;
    text-align: start;
    padding: 1.2rem;
    color: $text-color;
  }
}

@mixin button($width) {
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  width: $width;
  height: 5.6rem;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 1.6rem 2.4rem;
  span {
    @include buttonFont();
    text-align: center;
    position: relative;
    //z-index: 10;
  }
  &::before {
    content: '';
    width: 4.8rem;
    height: 4.8rem;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    position: absolute;
    transition: 300ms ease-in;
    top: 0.4rem;
    left: math.div($width, 2)-2.4rem;
  }
  &::after {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    position: absolute;
    transition: 300ms ease-in;
    top: 2.5rem;
    left: math.div($width, 2)-0.3rem;
  }
  &:hover::before {
    content: '';
    width: 1.1 * $width;
    height: 1.1 * $width;
    opacity: 1;
    position: absolute;
    top: (math.div(-1.1 * $width, 2) + 2.4rem);
    left: -1rem;
  }
  &:active::after {
    content: '';
    width: 1.1 * $width;
    height: 1.1 * $width;
    border-radius: 50%;
    background: transparent;
    opacity: 1;
    position: absolute;
    top: (math.div(-1.1 * $width, 2) + 2.4rem);
    left: -1.1rem;
  }
  &:focus {
    border: 3px solid $accent-a-3;
  }
  &:disabled {
    border-color: $gray-40;
    background: $gray-70;
    pointer-events: none;
    cursor: not-allowed;
    span {
      color: $gray-50;
    }
  }
}
@mixin button-mobile($width) {
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  width: $width;
  height: 5.6rem;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 1.2rem 1.2rem;
  span {
    @include button-s();
    text-align: center;
    position: relative;
    //z-index: 10;
  }
  &::before {
    content: '';
    width: 4.8rem;
    height: 4.8rem;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    position: absolute;
    transition: 300ms ease-in;
    top: 0.4rem;
    left: math.div($width, 2)-2.4rem;
  }
  &::after {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    position: absolute;
    transition: 300ms ease-in;
    top: 2.5rem;
    left: math.div($width, 2)-0.3rem;
  }
  &:hover::before {
    content: '';
    width: 1.1 * $width;
    height: 1.1 * $width;
    opacity: 1;
    position: absolute;
    top: (math.div(-1.1 * $width, 2) + 2.4rem);
    left: -1rem;
  }
  &:active::after {
    content: '';
    width: 1.1 * $width;
    height: 1.1 * $width;
    border-radius: 50%;
    background: transparent;
    opacity: 1;
    position: absolute;
    top: (math.div(-1.1 * $width, 2) + 2.4rem);
    left: -1.1rem;
  }
  &:focus {
    border: 3px solid $accent-a-3;
  }
  &:disabled {
    border-color: $gray-40;
    background: $gray-70;
    pointer-events: none;
    cursor: not-allowed;
    span {
      color: $gray-50;
    }
  }
}
@mixin buttonBlack {
  background: $black;
  span {
    @include buttonFont();
    color: $white;
  }
  &::before {
    background: $accent-a-3;
  }
  &::after {
    background: $accent-a-5;
  }
  &:hover::before {
    background: $accent-a-3;
  }
  &:active::after {
    border: 1px solid $accent-a-3;
  }
}
@mixin buttonWhite {
  background: $white;
  border: 0.1rem solid $black;
  span {
    @include buttonFont();
    text-decoration: none;
    color: $black;
  }
  &::before {
    background: $transparent-30-a-05;
  }
  &::after {
    background: $accent-a-05;
  }
  &:hover::before {
    background: $transparent-30-a-05;
  }
  &:active::after {
    border: 1px solid $transparent-30-a-05;
  }
}

@mixin buttonTransparent {
  background: transparent;
  border: none;
  svg {
    path {
      fill: $black;
    }
  }
  span {
    @include buttonFont();
    color: $black;
  }
  &:hover {
    svg {
      path {
        fill: $accent-a-4;
      }
    }
    span {
      @include buttonFont();
      color: $accent-a-4;
    }
  }
  &:focus {
    svg {
      path {
        fill: $accent-a-85;
      }
    }
    span {
      @include buttonFont();
      color: $accent-a-85;
    }
  }

  &:disabled {
    background: transparent;
    svg {
      path {
        fill: $gray-40;
      }
    }
    span {
      @include buttonFont();
      color: $gray-40;
    }
  }
}
@mixin link {
  text-decoration: none;
  transition: color 0.3s;
  &:hover {
    color: $accent-a-3;
    text-decoration: underline;
  }
  &:focus {
    color: $accent-a-85;
    text-decoration: underline;
  }
  &:active {
    color: $accent-a-5;
    text-decoration: underline;
  }
  &:disabled {
    color: $gray-70;
    pointer-events: none;
  }
}

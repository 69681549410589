.arrowBackIcon {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  svg {
    align-items: center;
    justify-content: center;
  }
}

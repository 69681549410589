@import '../../../styles/variables.module.scss';
.logOutBlock {
  width: 48.3rem;
  display: flex;
  flex-direction: column;
  gap: $xl-spacing;
  align-self: stretch;
  background: $gray-90;

  .logOutBlock__title {
    @include headline-2();
    text-align: start;
  }

  .logOutBlock__description {
    width: 33.6rem;
    @include body-3();
    text-align: start;
  }

  .logOutBlock__buttonsBlock {
    display: flex;
    flex-direction: row;
    gap: $xl-spacing;
  }

  .logOutBlock__buttonStay {
    @include button(22rem);
    @include buttonWhite();
  }

  .logOutBlock__buttonLogOut {
    @include button(14rem);
    @include buttonBlack();
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .logOutBlock {
    .logOutBlock__buttonStay {
      @include button-mobile(22rem);
    }

    .logOutBlock__buttonLogOut {
      @include button-mobile(14rem);
    }
  }
}
@media (max-width: 767px) {
  .logOutBlock {
    margin: unset;
    width: 32.8rem;
    padding: 0;
    .logOutBlock__title {
      display: none;
    }

    .logOutBlock__buttonsBlock {
      display: flex;
      flex-direction: row;
      gap: $xl-spacing;
    }

    .logOutBlock__buttonStay {
      @include button-mobile(22rem);
    }

    .logOutBlock__buttonLogOut {
      @include button-mobile(14rem);
    }
  }
}

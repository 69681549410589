@import '../../../../styles/variables.module.scss';
.checkboxField {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .checkbox {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    input[type=checkbox] {
      width: 1.6rem;
      height: 1.6rem;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $transparent70-gray10;
      background: $white;
      cursor: pointer;
      &:hover {
        border: 1px solid $accent-a-15;
        background-color: $white;
      }
      &:focus-within {
        outline: none;
        border: 1px solid $accent-a-15;
        background-color: $white;
        box-shadow: 0 0 0 4px #E7F7F2;
      }
    }
    input[type=checkbox]:checked {
      background: $white;
      border: 1px solid $accent-a-15;;
      &:hover {
        border: 1px solid $accent-a-15;;
        background-color: $accent-a-15;;
      }
      &:focus-within {
        outline: none;
        border: 1px solid $accent-a-15;;
        box-shadow: 0 0 0 4px #E7F7F2;
      }
    }
    input[type=checkbox]:checked:after {
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDNMNC41IDguNUwyIDYiIHN0cm9rZT0iIzFBNjQ0OSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    label {
      @include menu-5();
      color: $accent-a-95;
    }
    .checkboxLabel {
      @include menu-5();
      color: $accent-a-95;
    }
  }
}
.error {
  color: $error;
  padding-left: 0.8rem;
  @include menu-5();
}
.checkboxField.hasError {
  .checkbox {
    input[type=checkbox] {
      border-color: $error;
    }
    input[type=checkbox]:checked {
      border-color: $error;
    }
    label {
      color: $error;
    }
  }
}
@media (min-width: 769px) and (max-width: 1120px) {
  .checkboxField {
    .checkbox {
      label {
        @include menu-5();
      }
    }
  }
}
@media (max-width: 768px) {
  .checkboxField {
    .checkbox {
      label {
        @include menu-5();
      }
    }
  }
}

@import '../../styles/variables.module.scss';

.colorList {
  display: flex;
  gap: 1.6rem;
  margin-top: 1.6rem;
}

.colorListItem {
  width: 2.5rem;
  height: 2.5rem;

  & input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  & label {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    border: 0.1rem solid $black;
    cursor: pointer;
  }

  & input:checked + label::before {
    content: '';
    position: absolute;
    bottom: -0.4rem;
    width: 100%;
    height: 0;
    border: 0.1rem solid $black;
  }

  & input:disabled + label {
    position: relative;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      left: -0.45rem;
      right: -0.45rem;
      bottom: 1.1rem;
      height: 0.05rem;
      background-color: $bg-color;
      transform: rotate(45deg);
    }
  }
}

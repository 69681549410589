@import "../../styles/variables.module";
.userPage {
 padding-inline: 8rem;
 margin-top: 2.4rem;
 margin-bottom: 40rem;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .userPage {
    padding-inline: 3.2rem;
  }
}
@media (max-width: 767px) {
  .userPage {
    padding-inline: 3.2rem;
  }
}

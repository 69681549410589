@import "../../../styles/variables.module";
.ordersBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $xl-spacing;
  .ordersBlock__title {
    width: fit-content;
    @include headline-2();
    text-align: start;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .ordersBlock {
    .ordersBlock__title {
      display: none
    }
  }
}
@media (max-width: 767px) {
  .ordersBlock {
    .ordersBlock__title {
      display: none
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  color: inherit;
}

:focus {
  outline: 0;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

@import '../../styles/variables.module.scss';
.modalError {
  width: 49.9rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: $gray-90;
  padding: 1.6rem;
  position: fixed;
  right: 8rem;
  bottom: 9.2rem;
  z-index: 1001;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
}
.modalError__container {
  display: flex;
  justify-content: space-between;
}
.modalError__titleBlock {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  color: $error;
 @include headline-9();
}
.modalError__closeButton {
  background: inherit;
}
.modalError__content {
  width: 43.6rem;
  @include body-3();
  text-align: start;
}
.newCollectionBlock__button {
  @include button(19.5rem);
  @include buttonBlack();
}
@media (max-width: 36rem) {
  .modalError {
    width: 100%;
    padding-inline: 1.6rem;
    position: fixed;
    right: 0;
    bottom: 0;
  }
  .modalError__closeButton {
    background: inherit;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .modalError__content {
    width: 100%;
  }
  .modalError__titleBlock {
    @include tablet-headline-3();
  }
}

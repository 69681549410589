@import '../../styles/variables.module';
.productCardInCart {
  width: 100%;
  display: flex;
  flex-direction: row;
  .productCardInCart__imageBlock {
    flex: 0 14rem;
    height: 14rem;
    overflow: hidden;
    img {
      max-width: 100%;
      height: auto;
      object-fit: fill;
    }
  }
  .productCardInCart__infoBlock {
    flex-grow: 1;
    height: 14rem;
    display: flex;
    flex-direction: column;
    padding: $xl-spacing;
    .productCardInCart__namePriceBlock {
      display: flex;
      flex-direction: column;
      row-gap: $l-spacing;
      .productCardInCart__name {
        width: 19rem;
        @include headline-9();
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }
        .productCardInCart__price {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          @include headline-8();
        }
        .productCardInCart__discount {
          display: flex;
          flex-direction: row;
          gap: 1.6rem;
          @include headline-8();
          span:first-child {
            text-decoration: line-through;
            color: $gray-30
          }
          span:last-child {
            color: $error;
          }
      }
    }
    .productCardInCart__sizeColorQuantityDeleteBlock {
      display: flex;
      flex-direction: row;
      .productCardInCart__sizeColorQuantityBlock {
        @include headline-9();
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
            .productCardInCart__sizeBlock {
              width: 7rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: $l-spacing;
            }
            .productCardInCart__colorBlock {
              display: flex;
              align-items: center;
              flex-direction: row;
              gap: 1.6rem;
              .productCardInCart__colorRectangle {
                width: 1.5rem;
                height: 1.5rem;
                justify-content: center;
                align-items: center;
                border: $black 1px solid;
              }
            }
            .productCardInCart__quantityBlock {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 1.6rem;
              .productCardInCart__quantity {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 1.2rem;
                p {
                  text-align: center;
                  width: 2rem;
                }
                .productCardInCart__button {
                  background: transparent;
                }
              }
          }
        }
      .productCardInCart__buttonBlock {
      flex: 0 0 6rem;
      display: flex;
      align-self: flex-start;
      justify-content: flex-end;
      .productCardInCart__buttonDelete {
        width: 2.4rem;
        align-self: end;
        background: transparent;
      }
    }
    }
  }
}
.productCardInCart[type='1'] {
  width: 41rem;
  flex-grow: 1;
  gap: $xl-spacing;
  .productCardInCart__imageBlock {
    flex: 0 11rem;
    height: 11rem;
    img {
    }
  }
  .productCardInCart__infoBlock {
    height: 11rem;
    padding: 0;
    justify-content: space-between;
    .productCardInCart__namePriceBlock {
      .productCardInCart__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .productCardInCart__price {
      }
      .productCardInCart__discount {
        span:first-child {
        }
        span:last-child {
        }
      }
    }
    .productCardInCart__sizeColorQuantityDeleteBlock {
      .productCardInCart__sizeColorQuantityBlock {
        @include headline-9();
        flex-direction: column;
        .productCardInCart__sizeBlock {
        }
        .productCardInCart__colorBlock {
          .productCardInCart__colorRectangle {
          }
        }
        .productCardInCart__quantityBlock {
          .productCardInCart__quantity {
            p {
            }
            .productCardInCart__button {
              display: none;
            }
          }
        }
      }
      .productCardInCart__buttonBlock {
        .productCardInCart__buttonDelete {
          width: 1.8rem;
          height: 1.8rem;
          align-self: end;
          background: transparent;
        }
      }
    }
  }
}
.productCardInCart[type='2'] {
  width: 100%;
  display: flex;
  flex-direction: row;

  .productCardInCart__imageBlock {
    flex: 0 10rem;
    height: 10rem;
    img {
    }
  }
  .productCardInCart__infoBlock {
    padding-right: 0;
    height: 10rem;
    row-gap: $l-spacing;
    .productCardInCart__namePriceBlock {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .productCardInCart__name {
      }
      .productCardInCart__price {
      }
      .productCardInCart__discount {
        span:first-child {
        }
        span:last-child {
          color: $black;
        }
      }
    }
    .productCardInCart__sizeColorQuantityDeleteBlock {
      .productCardInCart__sizeColorQuantityBlock {
        flex-grow: 1;
        justify-content: unset;
        column-gap: $xxl-spacing;
        .productCardInCart__sizeBlock {
        }
        .productCardInCart__colorBlock {
          .productCardInCart__colorRectangle {
          }
        }
        .productCardInCart__quantityBlock {
          .productCardInCart__quantity {
            p {
            }
            .productCardInCart__button {
              display: none;
            }
          }
        }
      }
      .productCardInCart__buttonBlock {
        display: none;
        .productCardInCart__buttonDelete {
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width:1280px) {
  .productCardInCart {
    .productCardInCart__imageBlock {
      img {}
    }
    .productCardInCart__infoBlock {
      padding: $l-spacing;
      justify-content: space-between;
      .productCardInCart__namePriceBlock {
        .productCardInCart__name {}
        .productCardInCart__price {}
        .productCardInCart__discount {
          span:first-child {}
          span:last-child {}
        }
      }
      .productCardInCart__sizeColorQuantityDeleteBlock {
        .productCardInCart__sizeColorQuantityBlock {
          @include headline-9();
          flex-direction: row;
          .productCardInCart__sizeBlock {
          }
          .productCardInCart__colorBlock {
            .productCardInCart__colorRectangle {
            }
          }
          .productCardInCart__quantityBlock {
            .productCardInCart__quantity {
              p {
              }
              .productCardInCart__button {
              }
            }
          }
        }
        .productCardInCart__buttonBlock {
          align-self: center;
          .productCardInCart__buttonDelete {
            width: 1.8rem;
            height: 1.8rem;
            align-self: end;
            background: transparent;
          }
        }
      }
    }
  }
  .productCardInCart[type='1'] {
    width: 100%;
    .productCardInCart__imageBlock {
      img {}
    }
    .productCardInCart__infoBlock {
      .productCardInCart__namePriceBlock {
        .productCardInCart__name {}
        .productCardInCart__price {}
        .productCardInCart__discount {
          span:first-child {}
          span:last-child {}
        }
      }
      .productCardInCart__sizeColorQuantityDeleteBlock {
        .productCardInCart__sizeColorQuantityBlock {
          @include headline-9();
          flex-direction: row;
          .productCardInCart__sizeBlock {
          }
          .productCardInCart__colorBlock {
            .productCardInCart__colorRectangle {
            }
          }
          .productCardInCart__quantityBlock {
            .productCardInCart__quantity {
              p {
              }
              .productCardInCart__button {
              }
            }
          }
        }
        .productCardInCart__buttonBlock {
          .productCardInCart__buttonDelete {
          }
        }
      }
    }
  }
  .productCardInCart[type='2'] {
    width: 100%;
    display: flex;
    flex-direction: row;

    .productCardInCart__imageBlock {
      flex: 0 16.3rem;
      height: 13.4rem;
      img {
      }
    }
    .productCardInCart__infoBlock {
      padding: 0 0 0 $l-spacing;
      height: 13.4rem;
      row-gap: $s-spacing;
      .productCardInCart__namePriceBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .productCardInCart__name {
        }
        .productCardInCart__price {
        }
        .productCardInCart__discount {
          span:first-child {
          }
          span:last-child {
            color: $black;
          }
        }
      }
      .productCardInCart__sizeColorQuantityDeleteBlock {
        .productCardInCart__sizeColorQuantityBlock {
          flex-direction: column;
          flex-grow: 1;
          gap: $s-spacing;
          .productCardInCart__sizeBlock {
          }
          .productCardInCart__colorBlock {
            .productCardInCart__colorRectangle {
            }
          }
          .productCardInCart__quantityBlock {
            .productCardInCart__quantity {
              p {
              }
              .productCardInCart__button {
                display: none;
              }
            }
          }
        }
        .productCardInCart__buttonBlock {
          display: none;
          .productCardInCart__buttonDelete {
          }
        }
      }
    }
  }
  }

@media (max-width: 767px) {
  .productCardInCart {
    width: 100%;
    display: flex;
    flex-direction: row;
    .productCardInCart__imageBlock {
      flex: 0 12.3rem;
      height: 12.3rem;
      overflow: hidden;
      img {
        max-width: 100%;
        height: auto;
        object-fit: fill;
      }
    }
    .productCardInCart__infoBlock {
      height: 12.3rem;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 $l-spacing;
      .productCardInCart__namePriceBlock {
        .productCardInCart__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .productCardInCart__price {}
        .productCardInCart__discount {
          span:first-child {}
          span:last-child {}
        }
      }
      .productCardInCart__sizeColorQuantityDeleteBlock {
        .productCardInCart__sizeColorQuantityBlock {
          flex-direction: column;
          .productCardInCart__sizeBlock {
          }
          .productCardInCart__colorBlock {
            .productCardInCart__colorRectangle {
            }
          }
          .productCardInCart__quantityBlock {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1.6rem;
            .productCardInCart__quantity {
              display: flex;
              align-items: center;
              flex-direction: row;
              gap: 1.2rem;
              p {
                text-align: center;
                width: 2rem;
              }
              .productCardInCart__button {
              }
            }
          }
        }
        .productCardInCart__buttonBlock {
          flex: 0 0 2.4rem;
          display: flex;
          align-self: flex-start;
          justify-content: flex-end;
          .productCardInCart__buttonDelete {
            width: 2.4rem;
            align-self: end;
            background: transparent;
          }
        }
      }
    }
  }
  .productCardInCart[type='1'] {
    column-gap: $l-spacing;
    width: 30rem;
    .productCardInCart__imageBlock {
      flex: 0 11rem;
      height: 11rem;
    }
    .productCardInCart__infoBlock {
      justify-content: space-between;
      padding: 0;
      .productCardInCart__namePriceBlock {
        width: 100%;
        row-gap: $xs-spacing;
        justify-content: unset;
        .productCardInCart__name {
          width: 16rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include tablet-headline-5();
        }
        .productCardInCart__price {
          @include tablet-headline-4();
        }
        .productCardInCart__discount {
          @include tablet-headline-4();

          span:first-child {
            display: none;
          }

          span:last-child {
            color: $black;
          }
        }
      }
      .productCardInCart__sizeColorQuantityDeleteBlock {
        .productCardInCart__sizeColorQuantityBlock {
          @include headline-9();
          flex-direction: column;
          .productCardInCart__sizeBlock {
          }
          .productCardInCart__colorBlock {
            .productCardInCart__colorRectangle {
            }
          }
          .productCardInCart__quantityBlock {
            .productCardInCart__quantity {
              p {
              }
              .productCardInCart__button {
                display: none;
              }
            }
          }
        }
        .productCardInCart__buttonBlock {
          display: none;
          .productCardInCart__buttonDelete {
          }
        }
      }
    }
  }
  .productCardInCart[type='2'] {
    column-gap: $xl-spacing;
    width: 32.8rem;
    .productCardInCart__imageBlock {
      flex: 0 15.4rem;
      height: 15.4rem;
    }
    .productCardInCart__infoBlock {
      height: 15.4rem;
      padding: 0;
      .productCardInCart__namePriceBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: $xs-spacing;
        justify-content: unset;
        .productCardInCart__name {
          width: 100%;
          height: auto;
          @include tablet-headline-5();
        }
        .productCardInCart__price {
          width: 100%;
          justify-content: flex-start;
          @include tablet-headline-4();
        }

        .productCardInCart__discount {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 1.6rem;
          @include tablet-headline-4();

          span:first-child {
            display: none;
          }

          span:last-child {
            color: $black;
          }
        }
      }

      .productCardInCart__sizeColorQuantityBlock {
        width: 100%;
        flex-direction: column;
        row-gap: $xs-spacing;
        justify-content: unset;
        align-items: unset;
        @include tablet-headline-5();

        .productCardInCart__quantityBlock {
          .productCardInCart__quantity {
            .productCardInCart__button {
              display: none;
            }
          }
        }
      }
    }
    .productCardInCart__buttonBlock {
      display: none;
    }
  }
}



@import '../../../../styles/variables.module';
.checkOutUserInfoBlock {
  box-sizing: border-box;
  width: 52.1rem;
  display: flex;
  flex-direction: column;
  gap: $xl-spacing
}
.checkOutUserInfoBlock__title {
  @include headline_3();
  text-align: start;
}
.checkOutUserInfoBlock__radioBlock {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
}
.checkOutUserInfoBlock__radioWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  .checkOutUserInfoBlock__radioInput {
    appearance: none;
  }
  .checkOutUserInfoBlock__label {
    display: flex;
    flex-direction: row;
    width: 19.3rem;
    align-items: center;
    gap: $xs-spacing;
    @include headline-9();
    text-align: start;
  }
}

.checkOutUserInfoBlock__radioButton {
}
.checkOutUserInfoBlock__userPaymentMethodsList {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.checkOutUserInfoBlock__newCollectionBlock__button {
  display: block;
  margin: 0 auto;
  @include button($large);
  @include buttonBlack();
}
.checkOutUserInfoBlock__navigationButtonsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkOutUserInfoBlock__buttonLeft {
  @include button($small);
  @include buttonTransparent();
  justify-content: flex-start;
  padding-left: 0;
}
.checkOutUserInfoBlock__buttonRight {
  @include button($small);
  @include buttonTransparent();
  justify-content: flex-end;
  padding-right: 0;
  div {
    transform: rotate(180deg);
  }
}
@media (min-width: 769px) and (max-width: 1280px) {
  .checkOutUserInfoBlock {
    box-sizing: border-box;
    width: 70.3rem;
    display: flex;
    flex-direction: column;
  }
  .checkOutUserInfoBlock__title {
  }
  .checkOutUserInfoBlock__radioBlock {
    display: flex;
    flex-direction: column;
    row-gap: $xl-spacing;
  }
  .checkOutUserInfoBlock__radioWrapper {
  }
  .checkOutUserInfoBlock__label {
  }
  .checkOutUserInfoBlock__radioButton {
  }
  .checkOutUserInfoBlock__navigationButtonsSection {
  }
  .checkOutUserInfoBlock__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-inline: 0;
  }
  .checkOutUserInfoBlock__buttonRight {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-end;
    padding-inline: 0;
  }
}
@media (max-width: 768px) {
  .checkOutUserInfoBlock {
    box-sizing: border-box;
    width: 32.8rem;
    display: flex;
    flex-direction: column;
  }
  .checkOutUserInfoBlock__title {
  }
  .checkOutUserInfoBlock__radioBlock {
    display: flex;
    flex-direction: column;
    row-gap: $xl-spacing;
  }
  .checkOutUserInfoBlock__radioWrapper {
  }
  .checkOutUserInfoBlock__label {
  }
  .checkOutUserInfoBlock__radioButton {
  }
  .checkOutUserInfoBlock__navigationButtonsSection {
  }
  .checkOutUserInfoBlock__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-inline: 0;
  }
  .checkOutUserInfoBlock__buttonRight {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-end;
    padding-inline: 0;
  }
}


@import "../../styles/variables.module.scss";
.orderCompletePage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 2.7rem;
  margin: 3.2rem 8rem;
}
.orderCompletePage__titleBlock {
  display: flex;
  flex-direction: row;
  column-gap: 1.6rem;
}
.orderCompletePage__title {
  color: $accent-a-5;
  @include headline-4();
}
.orderCompletePage__numberBlock {
  display: flex;
  flex-direction: row;
  column-gap: 10.8rem;
  @include headline-8();
}
.orderCompletePage__textBlock {
  display: flex;
  flex-direction: column;
 @include body-2();
}
.orderCompletePage__buttonsBlock {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  margin-top: 2.1rem;

  a:first-child {
    @include buttonBlack();
    @include button(21rem);
    svg {
      path {
        fill: white;
      }
    }
  }

  a:last-child {
    @include buttonWhite();
    @include button(20rem);
  }
}
@media (min-width: 951px) and (max-width: 1280px) {
  .orderCompletePage {
    margin-inline: 8rem;
  }
  .orderCompletePage__titleBlock {
  }
  .orderCompletePage__title {
  }
  .orderCompletePage__numberBlock {
  }
  .orderCompletePage__textBlock {
  }
  .orderCompletePage__buttonsBlock {
    a:first-child {
      @include button-mobile(20rem);
      @include buttonBlack();
      svg {
        path {
          fill: white;
        }
      }
    }

    a:last-child {
      @include button-mobile(20rem);
      @include buttonWhite();
    }
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .orderCompletePage {
    margin-inline: 3.2rem;
  }
  .orderCompletePage__titleBlock {
  }
  .orderCompletePage__title {
  }
  .orderCompletePage__numberBlock {
  }
  .orderCompletePage__textBlock {
  }
  .orderCompletePage__buttonsBlock {
    a:first-child {
      @include button-mobile(20rem);
      @include buttonBlack();
      svg {
        path {
          fill: white;
        }
      }
    }

    a:last-child {
      @include button-mobile(20rem);
      @include buttonWhite();
    }
  }
}
@media (max-width: 767px) {
  .orderCompletePage {
    margin-inline: 3.2rem;
    width: 32.8rem;
  }
  .orderCompletePage__titleBlock {
  }
  .orderCompletePage__title {
  }
  .orderCompletePage__numberBlock {
    p:last-child {
      display: none;
    }
  }
  .orderCompletePage__textBlock {
  }
  .orderCompletePage__buttonsBlock {
    a:first-child {
      @include buttonWhite();
      @include button-mobile(21rem);
      svg {
        path {
          fill: $black;
        }
      }
    }

    a:last-child {
      @include buttonBlack();
      @include button-mobile(20rem);
    }
  }
}

@import '../../../styles/variables.module';

.selection {
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
  padding-bottom: 0.8rem;
  border-bottom: 0.1rem solid $black;
}
.iconBtn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  @include headline-6;
  background-color: inherit;
}
.sortBlock {
  position: relative;
}
.sortPopup {
  position: absolute;
  right: 0;
  top: 4.9rem;
  width: 19.3rem;
  padding-top: 0.8rem;
  border: 0.1rem solid $gray-30;
  border-top: none;
  background-color: $white;
  z-index: 2;
}
.filterIcon,
.sortIcon {
  width: 4rem;
  height: 4rem;
}
.activeName {
  @include body-3();
  height: 4.8rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 0.05rem solid $transparent70-gray10;
  }
  &:hover {
    background-color: $transparent-30-a-05;
    border-top: none;
    & + .activeName {
      border-top: none;
    }
  }
  &:active {
    background-color: $accent-a-05;
  }
}
@media (max-width: 950px) {
  .selection {
    margin-top: 2.4rem;
  }
  .filterIcon,
  .sortIcon {
    width: 2.4rem;
    height: 2.4rem;
  }
}
@media (max-width: 580px) {
  .iconBtn {
    @include headline-9;
  }
}

@import '../../../styles/variables.module.scss';
.faqBlock {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.helpTitle {
  @include headline-2();
}
.helpParagraph {
  @include running_line();
  color: $gray-20;
  margin-top: 2.4rem;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  max-width: 60rem;
  width: 100%;
  margin-top: 3.2rem;
}
.detailsTitle {
 @include headline-8();
}
.detailsParagraph {
  @include body-3();
  margin-top: 0.8rem;
}
.detailsList {
  list-style-type: circle;
  padding-left: 2rem;
  & li + li {
    margin-top: 1.2rem;
  }
}

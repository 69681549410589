@import '../../../../styles/variables.module.scss';
.checkOutShoppingCartBlockItemsList {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  min-height: 10rem;
  max-height: 35.6rem;
  padding: 0.4rem 0;
  position: relative;
}
.checkOutShoppingCartBlockItemsList__buttonsBlock {
  position: absolute;
  opacity: 0;
  top: 70%;
  right: 50%;
  z-index: 2;
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 2.6rem;
  &:hover {
    opacity: 1;
  }
}
.checkOutShoppingCartBlockItemsList__button {
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $accent-a-05;
  box-shadow: 0px -2px 4px 0px rgba(21, 80, 58, 0.25);
}
.checkOutShoppingCartBlockItemsList__buttonDown {
  transform: rotate(180deg)
}
.checkOutShoppingCartBlockItemsList__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.checkOutShoppingCartBlockItemsList__buttonDown {
  transform: rotate(180deg)
}
.checkOutShoppingCartBlockItemsList__cartList{
  display: flex;
  flex-direction: column;
  gap: $l-spacing;
}

@import "../../../../styles/variables.module";
.selectField {
  .label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    align-self: stretch;
    color: $accent-a-95;
    margin-bottom: 0.8rem;
    @include label();

    span {
      color: $error
    }
  }
  input {
    autoComplete: 'off'
  }
  .error {
    color: $error;
    margin-top: 0.8rem;
    padding-left: 0.8rem;
    @include menu-5();
  }
}
.selectField.hasError {
  .label {
    color: $error;
  }
  div {
    border-color: red!important;
    div {
      color: red!important;
    }
  }
}

@import "../../styles/variables.module";
.loginLayout {
  display: flex;
  width: 41rem;
  margin: $xl-spacing auto 17rem;
}
@media (min-width: 768px) and (max-width: 1280px){
  .loginLayout {
    width: 41rem;
    margin: $xl-spacing auto 5.6rem;
  }
}
@media (max-width: 767px) {
  .loginLayout {
    width: 32.5rem;
    margin: $xl-spacing auto 2rem;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.modalContent {
  background-color: #fff;
  max-width: 86rem;
}
.closeIcon {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  cursor: pointer;
}
@media (min-width: 581px) and (max-width: 950px) {
  .modalContent {
    max-width: 70.3rem;
  }
}
@media (max-width: 580px) {
  .modalContent {
    align-self: flex-end;
    width: 100vw;
  }
}

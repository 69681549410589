@import '../../../styles/variables.module.scss';

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  background-color: $gray-70;
  background-image: url('../../../../public/img/images/Catalog/catalogHero.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5.4rem 5.4rem 6rem;
}
.heroActive {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}
.heroTitle {
  @include headline-2();
  text-align: center;
}
.heroText {
  max-width: 60rem;
  width: 100%;
  @include body-3();
}
.heroBtn {
  @include button(15rem);
  @include buttonBlack();
  text-wrap: nowrap;
}
@media (min-width: 581px) and (max-width: 950px) {
  .hero {
    background-image: url('../../../../public/img/images/Catalog/tabletCatalogHero.webp');
    padding: 5.4rem 5.4rem 3.7rem;
  }
  .heroBtn {
    width: 16rem;
    height: 4.8rem;
    span {
      @include button-s();
      text-wrap: nowrap;
    }
  }
}
@media (max-width: 580px) {
  .hero {
    background-image: url('../../../../public/img/images/Catalog/mobileCatalogHero.webp');
    padding: 5.1rem 2.6rem;
  }
  .heroTitle {
    max-width: unset;
    @include tablet-headline-2();
  }
  .heroActive {
    gap: 0.8rem;
  }
  .heroBtn {
    width: 16rem;
    height: 4.8rem;
    span {
      @include button-s();
      text-wrap: nowrap;
    }
  }
}

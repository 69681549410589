@import "../../../styles/variables.module.scss";
.userDeleteBlock {
    &__button {
        @include buttonWhite();
        @include button(23rem);
    }
}

@media (max-width: 767px) {
    .userDeleteBlock {
        &__button {
            @include button-mobile(23rem);
        }
    }
}

@import '../../../../styles/variables.module';
.textField {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;

  .label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    align-self: stretch;
    color: $accent-a-95;
    @include label();

    span {
      color: $error
    }
  }
  .inputBlock {
    display: flex;
    height: 4.8rem;
    padding: 1.4rem;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border: 1px solid $gray-30;
    background: $white;

    &:focus-within   {
      outline: none;
      border: 3px solid $accent-a-3;
    }

    input {
      width: 100%;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;

      background: $white;
      @include input-text();

    }
    input[type='password'] {
      &::placeholder {
        padding-top: 2rem;
        -webkit-text-security: disc;
      }

    }
    button {
      background: $white;
      color: $gray-20;
    }

    .clearButton {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      svg {
        path {
          stroke: $gray-20;
        }
      }
    }}
  .error {
    color: $error;
    padding-left: 0.8rem;
   @include menu-5();
  }
  .block {
    display: inline;
  }
}

.textField.hasError {
  .label {
    color: $error;
  }

  .inputBlock {
    border-color: $error;
    &:focus-within {
      outline: none;
      border: 3px solid $accent-a-3;
    }

    input,
    .clearButton {
      color: $error;
      svg {
        path {
          stroke: $error;
        }
      }
    }
    button {
      div {
        svg {
          path {
            stroke: $error;
          }
        }
      }
    }

    input::placeholder {
      color: $error;
    }
  }
}
.textField.hasError[type='password'] {
  .inputBlock {
    button {
      div {
        svg {
          path {
            stroke: $error;
          }
        }
      }
    }
  }
}


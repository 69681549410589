@import '../../../styles/variables.module.scss';

.aboutBlock {
  display: flex;
  gap: 2.7rem;
}

.imgs {
  display: block;
  position: relative;
  width: 100%;
  text-align: start;
  aspect-ratio: 3/2;
  flex: 50% 1 1;
}

.mainImg {
  display: block;
  width: 100%;

  img {
    width: 95.5%;
    aspect-ratio: 3/2;
  }
}

.secondImg {
  display: block;
  position: absolute;
  width: 30.5%;
  bottom: 0;
  right: 0;

  img {
    width: 100%;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  flex: 50% 1 1;
  justify-content: center;
  text-align: start;
}

.newsLetterBlock__title2 {
  max-width: 28rem;
  padding-bottom: 0.8rem;
  @include headline-4();
  border-bottom: 0.1rem solid $black;

  span {
    @include headline_3();
  }
}
.modalCookies__text {
 @include body-3();
  max-width: 60rem;
}
@media (min-width: 581px) and (max-width: 768px) {
  .aboutBlock {
    gap: 1.6rem;
  }
  .description {
    gap: 1.6rem;
  }
}
@media (max-width: 650px){
  .secondImg{
    top: 11rem;
  }
}
@media (max-width: 580px) {
  .aboutBlock {
    flex-direction: column;
    gap: 2.4rem;
  }
  .description {
    gap: 0.8rem;
  }
  .newsLetterBlock__title2 {
    padding-bottom: 0.4rem;
  }
    .secondImg{
    top: unset;
  }
}

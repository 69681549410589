@import '../../styles/variables.module.scss';
.itemPreviewCard {
  display: flex;
  flex-direction: column;
  row-gap: $s-spacing;
  position: relative;
  width: 41rem;

  .itemPreviewCard__image {
    width: 100%;
    height: 50rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .itemPreviewCard__status {
    cursor: pointer;
    padding: 0.8rem 1rem;
    background: $black;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: absolute;
    top: 1.6rem;
    left: -0.3rem;

    .itemPreviewCard__statusText {
      @include headline-8();
      color: $white;
    }
    &:hover {
      background: $accent-a-3;
    }
    &:active {
      background: $accent-a-5;
    }
  }
  .itemPreviewCard__description {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    text-align: start;
    padding: 0.4rem 0.8rem;
    .itemPreviewCard__name {
      @include body-2();
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .itemPreviewCard__price {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      @include headline-8();
    }
    .itemPreviewCard__discount {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      @include headline-8();
      span:first-child {
        text-decoration: line-through;
        color: $gray-30;
      }
    }
  }
  .itemPreviewCard__heart {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  &:hover {
    cursor: pointer;
    .itemPreviewCard__description,
    .itemPreviewCard__price {
      span {
        color: $accent-a-7;
      }
      text-decoration: underline;
      color: $accent-a-7;
    }
  }
  &:active {
    cursor: pointer;
    .itemPreviewCard__description,
    .itemPreviewCard__price {
      span {
        color: $accent-a-4;
      }
      text-decoration: underline;
      color: $accent-a-4;
    }
  }
}

.disabledItemPreviewCard {
  pointer-events: none;

  .disabledItemPreviewCard__image {
    opacity: 0.5;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .itemPreviewCard {
    width: 34.5rem;

    .itemPreviewCard__image {
      height: 42rem;
      img {
      }
    }
    .itemPreviewCard__status {
      padding: 0.7rem 1rem;
      left: 0;

      .itemPreviewCard__statusText {
        @include headline-12();
      }
    }
    .itemPreviewCard__description {
      .itemPreviewCard__name {
        @include body-3();
      }
      .itemPreviewCard__price {
        @include tablet-headline-3();
      }
      .itemPreviewCard__discount {
        @include tablet-headline-3();
        span:first-child {
        }
      }
    }
    .itemPreviewCard__heart {
    }

    &:hover {

      .itemPreviewCard__description,
      .itemPreviewCard__price {
        span {
        }
      }
    }
    &:active {
      .itemPreviewCard__description,
      .itemPreviewCard__price {
        span {
        }
      }
    }
  }

  .disabledItemPreviewCard {
    .disabledItemPreviewCard__image {
    }
  }
}
@media (max-width: 767px) {
  .itemPreviewCard {
    width: 15.6rem;
    row-gap: 0.6rem;

    .itemPreviewCard__image {
      width: 100%;
      height: 18.4rem;
      img {
      }
    }
    .itemPreviewCard__status {
      top: 0.8rem;
      left: 0;

      .itemPreviewCard__statusText {
        @include headline-12();
      }
      &:hover {
      }
      &:active {
      }
    }
    .itemPreviewCard__description {
      padding: 0 0.8rem;
      .itemPreviewCard__name {
        @include body-4();
      }
      .itemPreviewCard__price {
        display: flex;
        flex-direction: row;
        gap: 1.6rem;
        @include tablet-headline-3();
      }
      .itemPreviewCard__discount {
        @include tablet-headline-3();
        span:first-child {
          text-decoration: line-through;
          color: $gray-30;
        }
      }
    }
    .itemPreviewCard__heart {
      width: 1.8rem;
      height: 1.8rem;
      top: 0.8rem;
      right: 0.8rem;
    }

    &:hover {
      .itemPreviewCard__description,
      .itemPreviewCard__price {
        span {
        }
      }
    }
    &:active {
      .itemPreviewCard__description,
      .itemPreviewCard__price {
        span {
        }
      }
    }
  }

  .disabledItemPreviewCard {
    .disabledItemPreviewCard__image {
    }
  }
}


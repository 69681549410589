@import '../../../../styles/variables.module.scss';
.radioButtonCheckedIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: $gray-90;
  &:hover {
    svg {
      path {
        fill: $accent-a-3
      }
    }
  }

  &:active {
    svg {
      path {
        fill: $accent-a-5
      }
    }
  }

  &:disabled {
    svg {
      path {
        fill: $gray-70;
      }
    }
  }

  &:focus {
    box-sizing: border-box;
    border: 0.5px solid $accent-a-3;
    border-radius: 50%;
  }
}

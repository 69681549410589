@import '../../../styles/variables.module.scss';
.checkOutPageUserInfo {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
  margin: 6rem 8rem;
  justify-content: space-between;
}
@media (min-width: 769px) and (max-width: 1280px) {
  .checkOutPageUserInfo {
    margin-top: $l-spacing;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    column-gap: 2.4rem;
  }
}
@media  (max-width: 768px) {
  .checkOutPageUserInfo {
    margin-top: $l-spacing;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    column-gap: 2.4rem;
  }
}

@import "variables.module";
.custom {
  &__control {
    border-radius: 0 !important;
    background-color: $white !important;
    border: 1px solid $gray-30 !important;
    height: 4.8rem;
  }
  &__value-container {

    padding-left: 1.4rem!important;
  }
  &__input-container {
  }
  &__single-value {
    @include input-text();
    color: $gray-40!important;
    text-align: start;
  }
  &__indicator-separator {
    display: none!important;
  }
  &__input {

  }
  &__indicator {
    color: $gray-20!important;
  }
  &__menu {
    height:17rem!important;
    padding-top: 0.8rem!important;
    box-shadow: none!important;
    margin-top: 0!important;
    border: 1px solid $black!important;
    border-radius: 0!important;
    box-sizing: border-box!important;
    z-index: 2000!important;
  }
    &__menu-list {
      height:16rem!important;
    }
      &__option {
        @include input-text();
        font-size: 16px!important;
        text-align: start!important;
        text-wrap: none!important;
        border-bottom: 0.5px solid $gray-30!important;
        overflow: hidden!important;
        white-space: nowrap!important;
        text-overflow: ellipsis!important;
      }
  &__menu-noties--no--options {
    text-align: start!important;
  }
  &__option--is-selected {
    background-color: $accent-a-05!important;
    color: $black!important;
  }
  &__option--is-focused {
    background-color: $transparent-30-a-05!important;
    color: $black!important;
  }
  &__menu-noties--no--options {
    display: none!important;
  }
  &__placegolder {
    font-size: 16px!important;
  }
  &__input-container {
    font-size: 16px!important;
  }
}

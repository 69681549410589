@import '../../../styles/variables.module.scss';

.catalog {
  display: flex;
  flex-direction: column;
  width: 100%;

  .filterBlock {
    display: none;
  }

  .cardsContainer {
    .cards {
      margin-top: 2.4rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $xl-spacing;
      .card {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .notFound {
        //max-width: 62.8rem;
        grid-column-start: 1;
        grid-column-end: 4;
        width: 100%;
        text-align: center;
        margin: 2.4rem auto;

        .notFoundTitle {
          @include headline_3();
        }

        .notFoundText {
          @include body-2();
          margin-top: 4rem;
        }

      }
    }

  }
}
.catalogFilter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: $xl-spacing;

  .filterBlock {
    grid-column: 1/2;
    border-right: 1px solid $black;
  }
  .cardsContainer {
    grid-column: 2/4;

    .cards {
      margin-top: 2.4rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $xl-spacing;
      .card {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      .notFound {
        //max-width: 62.8rem;
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        text-align: center;
        margin: 2.4rem auto;

        .notFoundTitle {
          @include headline_3();
        }

        .notFoundText {
          @include body-2();
          margin-top: 4rem;
        }

      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1.6rem 2.4rem 3.6rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-top: 0.1rem solid $gray-80;

  .arrow {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    min-width: 2.4rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    cursor: pointer;

    &.arrowDisable {
      opacity: 0;
      cursor: default;
    }
  }
  .btnBlock{
    .pageButton {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      padding: 1.2rem;
      width: 4rem;
      border-radius: 0.8rem;
      background-color: inherit;

      &.activeBtn {
        color: $accent_4;
        background-color: $transparent-30-a-05;
      }
    }
  }
  .arrowForward {
    svg {
      transform: rotate(180deg);
    }
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .catalog {
    .filterBlock {
    }
    .cardsContainer {
      .cards {
        grid-template-columns: repeat(2, 1fr);
        .card {
        }
        .notFound {
          max-width: 62.8rem;
          width: 100%;
          text-align: center;
          margin: 2.4rem auto;

          .notFoundTitle {
            @include headline_3();
          }

          .notFoundText {
            @include body-2();
            margin-top: 4rem;
          }

        }
      }

    }
  }
  .catalogFilter {
    grid-template-columns: repeat(2, 1fr);
    .filterBlock {
      grid-column: 1/2;
      border-right: 1px solid $black;
    }
    .cardsContainer {
      grid-column: 2/3;

      .cards {
        grid-template-columns: repeat(1, 1fr);
        .card {
        }
        .notFound {
          .notFoundTitle {
          }
          .notFoundText {
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .catalog {
    .filterBlock {
      display: none;
    }
    .cardsContainer {
      .cards {
        grid-template-columns: repeat(2, 1fr);
        gap: $l-spacing;
        .card {
        }
        .notFound {
          width: 32.8rem;
          margin: 0 auto 2.4rem;

          .notFoundTitle {
            @include tablet-headline-3();
          }

          .notFoundText {
            @include body-3();
            margin-top: 1.6rem;
          }

        }
      }

    }
  }
  .catalogFilter {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $xl-spacing;

    .filterBlock {
      grid-column: 1/2;
      border-right: unset;
    }
    .cardsContainer {
      display: none;

      .cards {
        margin-top: 2.4rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $xl-spacing;
        .card {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .notFound {
          max-width: 62.8rem;
          width: 100%;
          text-align: center;
          margin: 2.4rem auto;

          .notFoundTitle {
            @include headline_3();
          }

          .notFoundText {
            @include body-2();
            margin-top: 4rem;
          }

        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1.6rem 2.4rem 3.6rem;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-top: 0.1rem solid $gray-80;

    .arrow {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      min-width: 2.4rem;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      cursor: pointer;

      &.arrowDisable {
        opacity: 0;
        cursor: default;
      }
    }
    .btnBlock{
      .pageButton {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        padding: 1.2rem;
        width: 4rem;
        border-radius: 0.8rem;
        background-color: inherit;

        &.activeBtn {
          color: $accent_4;
          background-color: $transparent-30-a-05;
        }
      }
    }
    .arrowForward {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}


.mainPage {
  display: flex;
  flex-direction: column;
  row-gap: 10rem;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .mainPage {
    row-gap: 3.2rem;
  }
}
@media (max-width: 767px) {
  .mainPage {
    row-gap: 2.4rem;
  }
}

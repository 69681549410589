@import '../../../../styles/variables.module';
.textField {
  cursor: not-allowed;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;

}
.label {
  cursor: not-allowed;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;
  color: $gray-80;
  @include label();

  span {
    color: $gray-80
  }
}

.inputBlock {
  display: flex;
  height: 4.8rem;
  padding: 1.4rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border: 1px solid $gray-80;
  background: $white;

  input {
    cursor: not-allowed;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

    background: $white;
    @include input-text();

  }
  input::placeholder {
    color: $gray-80;
  }
  input[type='password'] {
    div {
      color: $gray-80;
    }
  }
  button {
    cursor: not-allowed;
    background: $white;
    color: $gray-80;
    div {
      svg {
        path {
          stroke: $gray-80;
        }
      }
    }
  }
}
.error {
  color: var(--error, #EA001B);
 @include menu-5();
}
.block {
  display: inline;
}

@import '../../styles/variables.module';

.aboutUs {
  padding: 1.6rem 8rem 4rem;
}

.aboutUsContainer {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.navigation {
  margin-bottom: 1.4rem;
}
@media (min-width: 581px) and (max-width: 1280px) {
  .aboutUs {
    padding: 4.8rem 3.2rem;
  }
  .aboutUsContainer {
    gap: 4.8rem;
  }
  .navigation {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 580px) {
  .aboutUs {
    padding: 1.6rem;
  }
  .aboutUsContainer {
    gap: 5.6rem;
  }
}

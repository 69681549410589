@import '../../styles/variables.module';
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
}

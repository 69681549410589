@import '../../../../styles/variables.module';
.userPaymentMethodsList {
  box-sizing: border-box;
  width: 52.1rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
.userPaymentMethodsList__title {
  text-align: start;
  @include headline_3();
}
.userPaymentMethodsList__block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.userPaymentMethodsList__radioWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.8rem;
}
.userPaymentMethodsList__radioButton {
}
.userPaymentMethodsList__label {
  display: flex;
  flex-direction: row;
  width: 30rem;
  @include body-3();
  text-align: start;
  word-wrap: inherit;
  margin-left: 0.8rem;
}
.userPaymentMethodsList__buttonLeft {
  @include button($small);
  @include buttonTransparent();
  justify-content: flex-start;
  padding-left: 0;
}
@media (min-width: 769px) and (max-width: 1120px) {
  .userPaymentMethodsList {
    width: 70.3rem;
  }
  .userPaymentMethodsList__title {
  }
  .userPaymentMethodsList__block {
  }
  .userPaymentMethodsList__radioWrapper {
  }
  .userPaymentMethodsList__radioButton {
  }
  .userPaymentMethodsList__label {

  }
  .userPaymentMethodsList__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .userPaymentMethodsList {
    width: 32.8rem;
  }
  .userPaymentMethodsList__title {
  }
  .userPaymentMethodsList__block {
  }
  .userPaymentMethodsList__radioWrapper {
  }
  .userPaymentMethodsList__radioButton {
  }
  .userPaymentMethodsList__label {

  }
  .userPaymentMethodsList__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-left: 0;
  }
}

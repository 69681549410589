@import '../../styles/variables.module.scss';

.cardSection {
  width: 100%;
  padding: 1.6rem 8rem 5.4rem;
}
.navigation {
  @include breadcrumb();
  text-align: start;
  margin-bottom: 1.4rem;
  text-transform: capitalize;
}
.card {
  display: flex;
  gap: 2.4rem;
  padding-bottom: 4rem;
}
.imgsContainer {
  display: flex;
  gap: 2.4rem;
  flex: 1 1 50%;
}
.imgs {
  width: 20%;
  max-width: 8.5rem;
  max-height: 68.2rem;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.imgsList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2.4rem;
  width: 100%;

  & li {
    width: 100%;

    img {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
      transition: scale 0.3s ease-in-out 0s;

      &:hover {
        scale: 1.05;
      }
    }
  }
}
.about {
  max-width: 50%;
  width: 100%;
}
.aboutInner {
  position: relative;
}
.mainImg,
.aboutInner {
  max-width: 52rem;
  width: 100%;
}
.mainImg {
  img {
    width: 100%;
    height: 68.2rem;
    object-fit: cover;
    object-position: top;
  }
}
.buyForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.registerForm__titleBlock {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
.imgHeart,
.aboutInnerHeart {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  align-self: flex-start;
}
.aboutInnerHeart {
  position: absolute;
  right: 0;
  top: 0;
}
.imgHeart {
  display: none;
}
.buyFormTitle {
  font-size: 2.4rem;
  text-transform: uppercase;
}
.priceBlock {
  display: flex;
  gap: 1.6rem;
  margin-bottom: 3.2rem;
  font-size: 1.8rem;
}
.unCurrentPrice {
  color: $gray-30;
  text-decoration: line-through;
}

// .buyFormPrice {
//   font-size: 2rem;
// }

.color {
  margin-bottom: 3.2rem;
}
.color,
.size {
  text-align: start;
  font-size: 1.8rem;
  display: inline-block;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
}
.btnGuide {
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 1.6rem;
  background-color: inherit;
}
.formBag {
  display: flex;
  margin-top: 1.6rem;
  width: 100%;

  .activeBtn {
    @include button(51.7rem);
    @include buttonBlack();
  }
  .disableBtn {
    width: 100%;
    padding: 1.2rem 0;
    background-color: $gray-90;
    color: $gray-50;
    border: 0.1rem solid $gray-50;
    text-align: center;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    text-transform: uppercase;
    cursor: default;
  }
}
.descriptions {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
@media (min-width: 581px) and (max-width: 1280px) {
  .cardSection {
    width: 100%;
    padding: 1.6rem 3.2rem 3.5rem;
  }
  .imgsContainer {
    flex-direction: column-reverse;
    gap: 1.6rem;
  }
  .imgsList {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    width: 100%;
    height: 100%;
  }
  .imgs {
    width: 100%;
    max-width: unset;
    max-height: unset;
  }
}
@media (max-width: 775px) {
  .buyFormTitle {
    max-width: 20rem;
  }
}
@media (max-width: 580px) {
  .cardSection {
    width: 100%;
    padding: 1.6rem 1.6rem 3.2rem;
  }
  .card {
    flex-direction: column;
  }
  .imgsContainer {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.6rem;
  }
  .imgsList {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    width: 100%;
    height: 100%;
    & li img {
      object-position: center;
    }
  }
  .imgs {
    width: 100%;
    max-width: unset;
    max-height: unset;
  }
  .mainImg {
    max-width: 39rem;
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 40rem;
      object-fit: cover;
      object-position: center;
    }
  }
  .imgHeart {
    display: block;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }
  .about,
  .aboutInner,
  .buyFormTitle {
    max-width: unset;
  }
  .aboutInnerHeart {
    display: none;
  }
  .formBag {
    .activeBtn {
      width: 100%;
    }
  }
  .color,
  .size {
    @include headline-9();
  }
}

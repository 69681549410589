@import "../../../../styles/variables.module";
.phoneField.hasError {
  color: $error;
  .phoneLabel {
    color: $error
  }
  .phoneInputContainer {
    border-color: $error;
  }
}
.phoneField {
  .phoneLabel {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    align-self: stretch;
    color: $accent-a-95;
    margin-bottom: 0.8rem;
    @include label();

    span {
      color: $error
    }
  }
  .phoneInputContainer {
    height: 4.8rem ;
    border-radius: 0;
    border: 1px solid $gray-30;
    background: $white;
    display: flex;
    align-items: center;
  }
  .phoneInputInput {
    padding-left: 6rem !important;
    border: 1px solid $white !important;
    background: $white !important;
    @include input-text();
    font-family: 'Lato', sans-serif !important;
    font-size: 1.6rem !important;
    &:hover {
      background: $white !important;
    }
  }
  .phoneInputButton {
    background: $white !important;
    border: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    &:hover {
      background: $white !important;
    }
  }
  .phoneInputDropdown {
    text-align: start;
  }
  .error {
    margin-top: 0.8rem;
    padding-left: 0.8rem;
    color: var(--error, #EA001B);
   @include menu-5();
    text-align: start;
  }
}

@import '../../../../styles/variables.module.scss';

.checkout {
  width: 100%;
  padding: 4rem 8rem 8rem;
  position: relative;
  display: flex;

  flex-direction: column;
  gap: 3.2rem;

  .closeIcon {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;
    background-color: inherit;
  }

  h2 {
    @include headline-4();
    text-align: center;
  }
}

.checkoutContainer {
  display: flex;
  gap: 2.4rem;

  img {
    max-width: 10rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
  }

  p {
    @include headline-9();
  }
}

.name {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
}

.checkoutContent {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 46.5rem;
  width: 100%;
  justify-content: flex-start;
}

.dataBlock {
  display: flex;
  gap: 5.6rem;
  justify-content: flex-start;
  align-items: center;
}

.priceBlock {
  display: flex;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.coloredSquare {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.4rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 0.1rem solid $black;
}

.paramsBlock {
  @include headline-9();
  display: flex;
  gap: 1.6rem;
}

.quantityBlock {
  display: flex;
  gap: 1.2rem;
}

.quantityBtn {
  background-color: inherit;
}

.trash {
  display: flex;
  align-items: center;
  width: 2.4rem;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  span {
    text-wrap: nowrap;
  }
}

.continueBtn {
  @include body-2();
  display: flex;
  align-items: center;
  gap: 0.8rem;
  background-color: inherit;
  text-transform: uppercase;
}

.checkoutBtn {
  @include button(30rem);
  @include buttonBlack();
  text-decoration: none !important;
}
@media (min-width: 581px) and (max-width: 950px) {
  .modalContent {
    max-width: 70.3rem;
  }
  .checkout {
    padding: 4rem 4.9rem 8rem;

    .closeIcon {
      top: 0.8rem;
      right: 1.6rem;
    }
  }
  .priceBlock {
    @include tablet-headline-3();
  }
  .paramsBlock {
    gap: 0.8rem;
  }
  .continueBtn {
    @include button-s();
  }
  .checkoutBtn {
    width: 23.6rem;
    height: 4.8rem;
  }
}
@media (max-width: 580px) {
  .checkout {
    padding: 2.4rem 1.6rem;
    h2 {
      max-width: 24rem;
      text-align: start;
      @include headline-9();
    }
  }
  .checkoutContainer {
    gap: 1.6rem;
    img {
      max-width: 15.6rem;
      width: 100%;
      object-position: center;
    }
  }
  .checkoutContent {
    gap: 0.8rem;
  }
  .dataBlock {
    margin-top: 0.8rem;
  }
  .closeIcon {
    right: 1.6rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .dataBlock {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }
  .btnContainer {
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    span {
      @include tablet-headline-3();
    }
  }
}

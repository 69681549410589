@import '../styles/variables.module.scss';

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 36rem;
  margin: 0 auto;
  background-color: $gray-90;
  min-height: 100vh;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  height: 100%;
  font-family: Poppins, Lato, Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.swiper-wrapper {
  margin-left: 3px;
}
@media (min-width: 581px) {
  .swiper > .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    display: none;
  }
}
@media (max-width: 580px) {
  .swiper-wrapper {
    padding-bottom: 16px;
  }
  .swiper > .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    top: unset !important;
    bottom: 0 !important;
  }
  .swiper-pagination-progressbar {
    background: rgba(215, 215, 215, 1) !important;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #040404 !important;
  }
}
@import 'styles/react-select.scss';
@import 'styles/variables.module.scss';

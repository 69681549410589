@import '../../../../../styles/variables.module.scss';
.userDeliveryAddressList {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.radioWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.8rem;
}
.label {
  display: flex;
  flex-direction: row;
  width: 30rem;
}
.newsLetterBlock__inputSection {
  appearance: none;
  color: $black;
}
.radioButton {
  width: 2.4rem;
}
.labelText {
  @include label();
  text-align: left;
  word-wrap: inherit;
  margin-left: 0.8rem;
}
.deleteButton {
  background: transparent;
  display: flex;
  align-content: flex-start;
}
.text {
  @include label();
  text-align: left;
  word-wrap: inherit;
}

@import '../../styles/variables.module.scss';

.sizeList {
  display: flex;
  gap: 1.6rem;
  margin-top: 1.6rem;
}

.sizeListItem {
  width: 4.5rem;
  height: 4rem;

  & input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 0.1rem solid $black;
    text-transform: uppercase;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  input:disabled + label {
    color: $gray-80;
    border-color: $gray-80;
    position: relative;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      left: -0.7rem;
      right: -0.7rem;
      height: 0.05rem;
      background-color: $gray-80;
      transform: rotate(42deg);
    }
  }

  & input:checked + label {
    background-color: $black;
    color: $white;
  }
}
@media (max-width: 580px) {
  .sizeListItem{
    width: 4rem;
    height: 3.4rem;
  }
}
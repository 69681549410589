@import '../../styles/variables.module';
.shoppingCartPage {
  padding-inline: 8rem;
  padding-bottom: 8rem;
  width: 100% ;
  display: flex;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  box-sizing: border-box;
  row-gap: $xl-spacing;

  .shoppingCartPage__navigation{
    @include breadcrumb();
    text-align: start;
    margin: 1.6rem 0 1.4rem;
  }

  .shoppingCartPage__titleBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .shoppingCartPage__infoBlock {
      width: 39.8rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $xl-spacing;

      .shoppingCartPage__title {
        @include headline_3();
      }
    }
    .shoppingCartPage__button {
      @include button(29.2rem);
      @include buttonWhite();
      display: flex;
      flex-direction: row;
      column-gap: 0.4rem;
      justify-content: center;
      align-items: center;
      div {
        position: relative;
        z-index: 10;
        width: 2.4rem;
        svg {
          width: 2.4rem;
        }
      }
    }
  }
}
.shoppingCartPage__emptyCartBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $xl-spacing;

  .shoppingCartPage__text {
   @include body-2();
    text-align: start;
    text-wrap: none;
  }
}
.shoppingCartPage__fillCartBlock {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;
  gap: $xl-spacing;

  .shoppingCartPage__itemsBlock {
    width: 84.5rem;
    padding-top: $xl-spacing;
    display: flex;
    flex-direction: column;
    gap: $xl-spacing;
  }
}
@media (min-width: 951px) and (max-width: 1280px) {
  .shoppingCartPage {
    left: 0;
    transform: translateX(0%);
    flex-direction: column;
    .shoppingCartPage__navigation{
      display: none;
    }
    .shoppingCartPage__titleBlock {
      justify-content: start;

      .shoppingCartPage__infoBlock {
        gap: $xl-spacing;

        .shoppingCartPage__title {
          margin-top: $l-spacing;
        }
      }
      .shoppingCartPage__button {
        display: none;
      }
    }
    .shoppingCartPage__emptyCartBlock {
      .shoppingCartPage__text {
      }
      .shoppingCartPage__slider {
        display: none;
      }
    }
    .shoppingCartPage__fillCartBlock {
      flex-direction: column;
      justify-content: unset;
      .shoppingCartPage__itemsBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $xl-spacing;
      }
      .shoppingCartPage__checkOutBlock {
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .shoppingCartPage {
    padding-inline: 3.2rem;
    left: 0;
    transform: translateX(0%);
    flex-direction: column;
    .shoppingCartPage__navigation{
     display: none;
    }
    .shoppingCartPage__titleBlock {
      justify-content: start;

      .shoppingCartPage__infoBlock {
        gap: $xl-spacing;

        .shoppingCartPage__title {
          margin-top: $l-spacing;
        }
      }
      .shoppingCartPage__button {
        display: none;
      }
    }
    .shoppingCartPage__emptyCartBlock {
    .shoppingCartPage__text {
    }
    .shoppingCartPage__slider {
      display: none;
    }
  }
    .shoppingCartPage__fillCartBlock {
    flex-direction: column;
    justify-content: unset;
    .shoppingCartPage__itemsBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $xl-spacing;
    }
    .shoppingCartPage__checkOutBlock {
      width: 100%;
    }
  }
  }
}
@media (max-width: 767px) {
  .shoppingCartPage {
    padding-inline: 3.2rem;
    left: 0;
    transform: translateX(0%);
    display: flex;
    flex-direction: column;
    margin-top: 2.6rem;
    row-gap: $xl-spacing;
    .shoppingCartPage__navigation{
      display: none;
    }
    .shoppingCartPage__titleBlock {
      display: flex;
      flex-direction: row;
      justify-content: start;

      .shoppingCartPage__infoBlock {
        width: 39.8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: $xxl-spacing;

        .shoppingCartPage__title {
          margin-top: $l-spacing;
          @include headline_3();
        }
      }
      .shoppingCartPage__button {
        display: none;
      }
    }
    .shoppingCartPage__emptyCartBlock {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      .shoppingCartPage__text {
        @include body-2();
        text-align: start;
        text-wrap: none;
      }
      .shoppingCartPage__slider {
        display: none;
      }
    }
    .shoppingCartPage__fillCartBlock {
      margin-top: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: unset;
      gap: 2.4rem;

      .shoppingCartPage__itemsBlock {
        width: 100%;
        padding-top: 2.4rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
      .shoppingCartPage__checkOutBlock {
        width: 100%;
      }
    }
  }
}

@import "../../../../styles/variables.module";
.userPaymentByCardForm {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .userPaymentByCardForm__note {
    @include body-3();
    color: $error;
    text-align: start;
  }
}
.block {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;

}

@import '../../../../styles/variables.module.scss';
.deliveryOptionsSection {
  box-sizing: border-box;
  width: 52.1rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
.deliveryOptionsSection__title {
  @include headline_3();
  text-align: start;
}
.deliveryOptionsSection__radioBlock {
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
}
.deliveryOptionsSection__radioWrapper {
  display: flex;
  flex-direction: row;
}
.deliveryOptionsSection__label {
  display: flex;
  flex-direction: row;
  gap: $xs-spacing;
  align-items: center;
  @include headline-9();
  text-align: start;
}
.deliveryOptionsSection__radioInput {
  appearance: none;
}
.deliveryOptionsSection__navigationButtonsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.deliveryOptionsSection__buttonLeft {
  @include button($small);
  @include buttonTransparent();
  justify-content: flex-start;
  padding-left: 0;
}
.deliveryOptionsSection__buttonRight {
  @include button($small);
  @include buttonTransparent();
  justify-content: flex-end;
  padding-right: 0;
  div {
    transform: rotate(180deg);
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .deliveryOptionsSection {
    width: 70.3rem;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
  }
  .deliveryOptionsSection__title {
  }
  .deliveryOptionsSection__radioBlock {
    flex-direction: column;
  }
  .deliveryOptionsSection__radioWrapper {
  }
  .deliveryOptionsSection__label {
  }
  .deliveryOptionsSection__radioButton {
  }
  .deliveryOptionsSection__navigationButtonsSection {
  }
  .deliveryOptionsSection__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-left: 0;
  }
  .deliveryOptionsSection__buttonRight {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-end;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .deliveryOptionsSection {
    width: 32.8rem;
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
  }
  .deliveryOptionsSection__title {
  }
  .deliveryOptionsSection__radioBlock {
    flex-direction: column;
  }
  .deliveryOptionsSection__radioWrapper {
  }
  .deliveryOptionsSection__label {
  }
  .deliveryOptionsSection__radioButton {
  }
  .deliveryOptionsSection__navigationButtonsSection {
  }
  .deliveryOptionsSection__buttonLeft {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-start;
    padding-left: 0;
  }
  .deliveryOptionsSection__buttonRight {
    @include button-mobile($small);
    @include buttonTransparent();
    justify-content: flex-end;
    padding-right: 0;
  }
}

@import '../../styles/variables.module';
.itemsList {
  width: 100%;
  flex-direction: column;

  .previewCardList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 41.1rem);
    gap: $l-spacing;
  }
}
@media (min-width: 1050px) and (max-width: 1280px) {
  .itemsList {
    .previewCardList {
      grid-template-columns: repeat(2, 34.5rem);
    }
  }
}
@media (min-width: 756px) and (max-width: 1049px) {
  .itemsList {
    .previewCardList {
      grid-template-columns: repeat(1, 34.5rem);
    }
  }
}
@media (max-width: 756px) {
  .itemsList {
    .previewCardList {
      grid-template-columns: repeat(2, 15.6rem);
      column-gap: $l-spacing;
    }
  }
}

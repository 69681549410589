@import '../../../styles/variables.module.scss';
.checkOutShoppingCartBlock {
  width: 51.9rem;
  padding: 1.6rem;
  background: $white;
  height: min-content;
}
.checkOutShoppingCartBlock__wrapper {
  display: flex;
  width: 41.1rem;
  flex-direction: column;
  gap: 1.7rem;
  box-sizing: border-box;
  margin-inline: auto;
}
.checkOutShoppingCartBlock__titleBlock {
  display: flex;
  flex-direction: row;
  column-gap: 1.2rem;
  @include headline-5();
}
.checkOutShoppingCartBlock__title {
  @include headline_3();
}
.checkOutShoppingCartBlock__form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .checkOutShoppingCartBlock__arrowButton {
    transform: rotate(180deg);
    @include button($extra-small);
    @include buttonTransparent();
    padding: 0;
    height: 4.6rem;
    justify-content: flex-start;
    align-items: center;
  }
}
.checkOutShoppingCartBlock__priceBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include headline_3();
}
.checkOutShoppingCartBlock__price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 @include body-3();
}
.checkOutShoppingCartBlock__discount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $error;
 @include body-3();
}
.checkOutShoppingCartBlock__button {
  @include button(41.1rem);
  @include buttonBlack();
}

@media (min-width: 769px) and (max-width: 1280px) {
  .checkOutShoppingCartBlock {
    width: 70.3rem;
    padding-top: $l-spacing;
    padding-bottom: $l-spacing;
  }
  .checkOutShoppingCartBlock__wrapper {
    display: flex;
    width: 100%;
  }
  .checkOutShoppingCartBlock__titleBlock {
    display: none;
  }
  .checkOutShoppingCartBlock__title {
  }
  .checkOutShoppingCartBlock__form {
    .checkOutShoppingCartBlock__arrowButton {
    }
  }
  .checkOutShoppingCartBlock__priceBlock {
  }
  .checkOutShoppingCartBlock__price {
  }
  .checkOutShoppingCartBlock__discount {
  }
  .checkOutShoppingCartBlock__button {
    @include button-mobile(67.1rem);
    padding-inline: 0;
  }
}
@media (max-width: 768px) {
  .checkOutShoppingCartBlock {
    width: 32.8rem;
    padding-top: $l-spacing;
    padding-bottom: $l-spacing;
  }
  .checkOutShoppingCartBlock__wrapper {
    display: flex;
    width: 100%;
  }
  .checkOutShoppingCartBlock__titleBlock {
    display: none;
  }
  .checkOutShoppingCartBlock__title {
  }
  .checkOutShoppingCartBlock__form {
    .checkOutShoppingCartBlock__arrowButton {
    }
  }
  .checkOutShoppingCartBlock__priceBlock {
  }
  .checkOutShoppingCartBlock__price {
  }
  .checkOutShoppingCartBlock__discount {
  }
  .checkOutShoppingCartBlock__button {
    @include button-mobile(29.6rem);
    padding-inline: 0;
  }
}

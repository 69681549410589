@import '../../../styles/variables.module';
.wishListBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 10rem;
}
.wishListBlock__title {
  @include headline-2();
  text-align: start;
}

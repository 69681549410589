@import '../../../../styles/variables.module.scss';
.emptyHeartIcon {
  width: 100%;
  height: 100%;
  transition: color 0.3s;
  &:hover {
    svg {
      path[stroke] {
        stroke: $accent-a-4;
      }
    }
  }
  &:active {
    svg {
      path[stroke] {
        stroke: $accent-a-8;
      }
    }
  }
}
// @media (max-width: 586px) {
//   .emptyHeartIcon {
//     width: 1.8rem;
//     height: 1.8rem;
//   }
// }

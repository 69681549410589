@import '../../../../styles/variables.module';

.guideContainer {
  width: 100%;
  padding: 4rem 8rem 8rem;
  position: relative;

  .closeIcon {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;
    background-color: inherit;
  }

  h2 {
    @include headline-4();
    text-align: center;
    margin-bottom: 3.2rem;
  }
}
.guideContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 3.2rem;

  p {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.0128rem;
  }
}
.guideTable {
  width: 100%;
  table-layout: fixed;
  @include body-3();
  border: 0.1rem solid $black;
  border-right: none;
  border-spacing: 0;
  text-align: center;

  th {
    border-bottom: 0.1rem solid $black;
  }

  td,
  th {
    @include headline-8();
    border-right: 0.1rem solid $black;
    padding: 1.6rem 0;
  }
}
@media (min-width: 581px) and (max-width: 950px) {
  .guideContainer {
    padding: 4rem 2.65rem 8rem;

    h2 {
      @include tablet-headline-2();
    }
  }
  .guideTable {
    th {
      @include menu-4();
    }
    td {
      @include body-5();
    }
  }
}
@media (max-width: 580px) {
  .guideContainer {
    padding: 2.4rem 1.6rem;

    h2 {
      text-align: left;
      @include tablet-headline-2();
    }
    .closeIcon {
      top: 2rem;
      right: 1.6rem;
    }
  }
  .guideContent {
    padding-bottom: 2.4rem;
    p {
      @include body-4();
    }
  }
  .guideTable {
    th {
      @include menu-4();
      padding: 0.4rem;
      vertical-align: middle;
    }
    td {
      @include body-5();
    }
  }
}

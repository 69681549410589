@import '../../../styles/variables.module';
.checkbox {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  input[type=checkbox] {
    width: $l-spacing;
    height: $l-spacing;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $transparent70-gray10;
    background: $white;
    cursor: pointer;
    &:hover {
      border: 1px solid $accent-a-15;
      background-color: $white;
    }
    &:focus-within {
      outline: none;
      border: 1px solid $accent-a-15;
      background-color: $white;
      box-shadow: 0 0 0 4px #E7F7F2;
    }
  }
  input[type=checkbox]:checked {
    background: $white;
    border: 1px solid $transparent70-gray10;
    &:hover {
      border: 1px solid $accent-a-15;;
      background-color: $accent-a-15;;
    }
    &:focus-within {
      outline: none;
      border: 1px solid $accent-a-15;;
      box-shadow: 0 0 0 4px #E7F7F2;
    }
  }
  input[type=checkbox]:checked:after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDNMNC41IDguNUwyIDYiIHN0cm9rZT0iIzFBNjQ0OSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  label {
    display: flex;
    flex-direction: row;
    @include body-3();
    color: $accent-a-95;
  }
  .checkboxLabel {
    display: flex;
    flex-direction: row;
    @include body-3();
    color: $accent-a-95;
  }
}
//.checkbox {
//  display: inline-flex;
//  .checkboxInput {
//    opacity: 0;
//    position: absolute;
//
//    &:hover + .checkboxLabel::before {
//      background-color: $gray-80;
//    }
//
//    &:checked + .checkboxLabel::before {
//      content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 6 6' fill='none'><path d='M0.599609 2.64001L2.39961 4.80001L5.39961 1.20001' stroke='%231A6449'/></svg>");
//    }
//  }
//  .checkboxLabel {
//    position: relative;
//    display: inline-flex;
//    align-items: center;
//    font-size: inherit;
//    font-style: normal;
//    font-weight: 400;
//    line-height: 110%;
//    cursor: pointer;
//
//    &::before {
//      content: '';
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      flex: 0 0 1.6rem;
//      height: 1.6rem;
//      width: 1.6rem;
//      border: 0.1rem solid $black;
//      margin: 0 0.8rem 0 0;
//      cursor: pointer;
//      transition: all 0.3s ease 0s;
//    }
//  }
//}



@import '../../../styles/variables.module.scss';
.deliveryBlock {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.helpTitle {
  @include headline-2();
}
.helpParagraph {
  @include running_line();
  color: $gray-20;
  margin-top: 2.4rem;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  max-width: 60rem;
  width: 100%;
  margin-top: 3.2rem;
}
ul.dropdownList {
  list-style-type: circle;
  padding-left: 2rem;
  margin-top: 0.8rem;
}
.detailsTitle {
 @include headline-8();
}
.detailsParagraph {
  @include body-3();
  margin-top: 0.8rem;
}
@media (min-width: 581px) and (max-width: 768px) {
  .helpParagraph {
    @include body-3();
    // color: $gray-20;
    // margin-top: 2.4rem;
  }
}
@media (max-width: 580px) {
  .helpTitle {
    @include tablet-headline-2();
  }
  .helpParagraph {
    margin-top: 0.9rem;
  }
  .description {
    gap: 2.4rem;
    max-width: unset;
    margin-top: 2.4rem;
  }
}

@import '../../../styles/variables.module.scss';

.fashionBlock {
  display: flex;
  min-height: 52.5rem;
  gap: 5.2rem;
}
.imgs {
  display: block;
  position: relative;
  // max-width: 62.8rem;
  // min-width: 48rem;
  // width: 100%;
  // max-height: 52.5rem;
  flex: 50% 1 1;
}
.mainImg,
.secondImg {
  display: block;
  width: 67%;
  img {
    width: 100%;
  }
}
.secondImg {
  position: absolute;
  top: 12.5rem;
  // bottom: 0;
  right: 0;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  flex: 50% 1 1;
  justify-content: center;
  order: -1;
  text-align: start;
}
.newsLetterBlock__title2 {
  max-width: 28rem;
  padding-bottom: 0.8rem;
  @include headline-4();
  border-bottom: 0.1rem solid $black-color;

  span {
    @include headline_3();
  }
}
.modalCookies__text {
  max-width: 60rem;
 @include body-3();
}
.fashionBlock_innerText {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 0.8rem;
  width: 100%;
  padding-bottom: 1.6rem;
}
@media (min-width: 581px) and (max-width: 768px) {
  .fashionBlock {
    min-height: 71.5rem;
    gap: 1.6rem;
  }
  .description {
    gap: 1.6rem;
  }
  .imgs {
    width: 100%;
    flex: 50% 1 1;
  }
  .mainImg,
  .secondImg {
    position: absolute;
    max-width: 78.7%;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .mainImg {
    position: absolute;
    right: 0;
  }
  .secondImg {
    left: 0;
    top: 22.4rem;
  }
}
@media (max-width: 580px) {
  .fashionBlock {
    min-height: 112rem;
    flex-direction: column;
    gap: 2.4rem;
  }
  .description {
    flex: unset;
    justify-content: flex-start;
    gap: 0.8rem;
  }
  .fashionBlock_innerText {
    padding-bottom: unset;
  }
  .imgs {
    flex: unset;
  }
  .secondImg {
    top: 6.5rem;
  }
}

@import "../../styles/variables.module.scss";
.modalCookies {
  width: 51.9rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  background: $gray-90;
  padding: 2.4rem;
  position: fixed;
  right: 7rem;
  bottom: 9.2rem;
  z-index: 1001;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);

  .modalCookies__container {
    display: flex;
    column-gap: 1.2rem;

    .modalCookies__content {
      width: 43.6rem;
     @include body-3();
      text-align: start;
    }

    .modalCookies__closeButton{
      width: 2.4rem;
      background: inherit;
    }
  }
}
.modalCookies__button {
  @include button(19rem);
  @include buttonBlack();
}
@media (max-width: 36rem) {
  .modalCookies {
    width: 100%;
    right: 0;
    bottom: 0;
  }
  .modalCookies__content {
    @include body-3();
    text-align: start;
  }
  .modalCookies__button {
    @include button-mobile(19rem);
    @include buttonBlack();
  }
}

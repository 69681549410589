@import '../../../styles/variables.module.scss';

.proposeBlock {
  display: flex;
  gap: 4rem;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  flex: 50% 1 1;
  align-items: center;
  justify-content: center;
}
.newsLetterBlock__title2 {
  max-width: 28rem;
  width: 100%;
  padding-bottom: 0.8rem;
  @include headline-4();
  border-bottom: 0.1rem solid $black;

  span {
    @include headline_3();
  }
}
.innerText {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 0.8rem;
  width: 100%;
  padding-bottom: 1.6rem;
}
.modalCookies__text {
  width: 100%;
  @include body-3();
}
.img {
  display: block;
  aspect-ratio: 4/3;
  max-width: 23.5%;
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}
.lastChild {
  order: 2;
}
.newCollectionBlock__button {
  @include button(16.2rem);
  @include buttonBlack();
}

@media (min-width: 581px) and (max-width: 768px) {
  .proposeBlock {
    gap: 1.6rem;
  }
  .newCollectionBlock__button {
    @include button(16rem);
    height: 4.8rem;
    span {
      @include button-s();
    }
  }
  .description {
    gap: 1.6rem;
  }
}
@media (max-width: 580px) {
  .proposeBlock {
    flex-direction: column;
    gap: 2.4rem;
  }
  .description {
    gap: 0.8rem;
  }
  .newsLetterBlock__title2 {
    width: 100%;
    padding-bottom: 0.4rem;
    text-align: center;
  }
  .img {
    max-width: unset;
  }
  .newCollectionBlock__button {
    @include button(16rem);
    height: 4.8rem;
    span {
      @include button-s();
    }
  }
}

@import "../../styles/variables.module";
.completeOrderPage {
  width: 100%;
  margin-bottom: 17rem;
  .completeOrderPage__titleBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    padding-bottom: 1.8rem;
    margin-bottom: 4rem;
    border-bottom: $gray-30 solid 0.1rem;
    .completeOrderPage__title {
      @include headline-2();
      padding-bottom: 1.2rem;
    }
    .completeOrderPage__backButton {
      width: fit-content;
      display: flex;
      flex-direction: row;
      column-gap: 0.4rem;
      @include button(36rem);
      @include buttonTransparent();
      @include buttonFont();
      .completeOrderPage__backButton__icon {
        width: 3.2rem;
      }
    }
  }
  .completeOrderPage__itemsList {
    width: 72.8rem;
    display: flex;
    flex-direction: column;
    row-gap: $xs-spacing;
    margin-bottom: $xl-spacing;
  }
  .completeOrderPage__orderInfoBlock {
    width: 72.8rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    .completeOrderPage__title {
        @include headline-8();
        justify-content: space-between;
      }
      div{
        display: flex;
        flex-direction: row;
      }
    .completeOrderPage__totalPrice {
      width: 100%;
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;
      background-color: $gray-80;
      @include headline-8();
    }
    .completeOrderPage__deliveryInfoBlock {
      width: 100%;
      display: flex;
      flex-direction: row;
      row-gap: 1.6rem;
      justify-content: space-between;
      .completeOrderPage__infoSection {
        width: 20rem;
        align-items: flex-start;
        @include body-3();
      }
      div {
        padding-right: 0;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .completeOrderPage {
    .completeOrderPage__titleBlock {
      margin-bottom: unset;
      border-bottom: unset;

      .completeOrderPage__title {
        display: none;
      }
      .completeOrderPage__backButton {
        margin-top: -1.6rem;
        margin-left: -4.6rem;
      }
    }
    .completeOrderPage__orderInfoBlock {
      width: 43.3rem;
      .completeOrderPage__title {
        @include tablet-headline-3();
      }
      .completeOrderPage__totalPrice {
        @include tablet-headline-3();
      }
      .completeOrderPage__deliveryInfoBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        justify-content: space-between;
        .completeOrderPage__infoSection {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: start;
          column-gap: $l-spacing;

          .completeOrderPage__title {
            width: 40%;
            display: flex;
          }
          div {
            width: 60%;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .completeOrderPage {
    .completeOrderPage__titleBlock {
      display: none;
      border-bottom: none;
      .completeOrderPage__title {
        display: none;
      }
    }
    .completeOrderPage__orderInfoBlock {
      width: 32.8rem;
      .completeOrderPage__title {
        @include tablet-headline-3();
      }
      .completeOrderPage__totalPrice {
        @include tablet-headline-3();
      }
      .completeOrderPage__deliveryInfoBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        justify-content: space-between;
        .completeOrderPage__infoSection {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          column-gap: $l-spacing;
          .completeOrderPage__title {
            width: 100%;
            display: flex;
          }
          div {
            width: 22.4rem;
          }
        }
      }
    }
  }
}

@import '../../../../styles/variables.module.scss';
.userDeliveryBlock {
  width: 100%;

  .userDeliveryBlock__deliveryTitle {
    @include headline_3();
    text-align: start;
    margin-bottom: 2.4rem;
  }

  .userDeliveryBlock__section {
    display: flex;
    flex-direction: row;
    column-gap: 13.4rem;

    .userDeliveryBlock__column {
      display: flex;
      flex-direction: column;
      row-gap: 1.8rem;
      width: 30rem;
      .userDeliveryBlock__blockLabel {
       @include headline-6();
        text-align: start;
      };
      .userDeliveryBlock__button {
        @include buttonBlack();
        @include button(19.3rem);
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .userDeliveryBlock {
    width: 43.1rem;
    .userDeliveryBlock__section {
      display: flex;
      flex-direction: column;
      row-gap: $xl-spacing;

      .userDeliveryBlock__column {
        width: 43.1rem;
        .userDeliveryBlock__blockLabel {
          @include headline-9();
          text-align: start;
        };
        .userDeliveryBlock__button {
          @include buttonBlack();
          @include button-mobile(19.3rem);
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .userDeliveryBlock {
    width: 32.8rem;
    .userDeliveryBlock__section {
      display: flex;
      flex-direction: column;
      row-gap: $xl-spacing;

      .userDeliveryBlock__column {
        width: 32.8rem;
        .userDeliveryBlock__blockLabel {
          @include headline-9();
          text-align: start;
        };
        .userDeliveryBlock__button {
          @include buttonBlack();
          @include button-mobile(19.3rem);
        }
      }
    }
  }
}

@import '../../../../../styles/variables.module.scss';
.userDeliveryMethodsList {
    display: flex;
    flex-direction: column;
    row-gap: $l-spacing;

    .radioOption {
      display: flex;

      .radioInput {
        appearance: none;
      }
      .radioLabel {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 0.7rem;
        align-items: center;
        cursor: pointer;
        text-align: start;
        @include label();
        .radioLabel__radioButton {
          width: 2.4rem;
        }
    }
}
}


@import "../../../../styles/variables.module";
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderIcon {
  width: 14.3rem;
  height: 14.3rem;
  animation: rotation 1.5s linear infinite;
}


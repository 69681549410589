@import '../../styles/variables.module.scss';

.container {
  max-width: $max-width;
  width: 100%;
  min-width: 36rem;
  padding-top: 8.9rem;
  margin: auto;
  flex: 1 0 auto;
  overflow: hidden;
}
//.marqueeContainer {
//  width: 100%;
//  position: fixed !important;
//  bottom: 0;
//  height: 4.9rem;
//  padding: 1rem 0;
//  background-color: $white-color;
//  opacity: 0.8;
//  z-index: 10001;
//}
@media (min-width: 768px) and (max-width: 1280px) {
  .container {
    padding-top: 10.8rem;
  }
}
@media (max-width: 768px) {
  .marqueeContainer {
    height: 4rem;
  }
}
@media (max-width: 767px) {
  .container {
    padding-top: 7.7rem;
  }
}

@import "../../../../styles/variables.module";
.textAreaField {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;

}
.label {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  align-self: stretch;
  color: $accent-a-95;
  @include label();

  span {
    color: $error
  }
}
.textarea {
    min-width: 30rem;
    max-width: 30rem;
    min-height: 3rem;
    height: 7.5rem;
    border: 1px solid $gray-30;
    padding: 1.4rem;
    background: $white;
    @include input-text();
  }
.textarea[type='1'] {
  min-width: 52.1rem;
  max-width: 52.1rem;
}
.error {
  color: $error;
  padding-left: 0.8rem;
 @include menu-5();
}
.block {
  display: inline;
}

.textAreaField.hasError {
  .label {
    color: $error;
  }
  .textarea {
    border-color: $error;

    div {
      color: $error;
    }
  }
  .textarea::placeholder {
    color: $error;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .textAreaField {
  }
  .label {
    span {
    }
  }
  .textarea {
    min-width: 70.3rem;
    max-width: 70.3rem;
    min-height: 3rem;
  }
  .textarea[type='1'] {
    min-width: 70.3rem;
    max-width: 70.3rem;
  }
  .error {
  }
  .block {
  }

  .textAreaField.hasError {
    .label {
    }
    .textarea {
      div {
      }
    }
    .textarea::placeholder {
    }
  }
}
@media (max-width: 767px) {
  .textAreaField {
  }
  .label {
    span {
    }
  }
  .textarea {
    min-width: 32.8rem;
    max-width: 32.8rem;
    min-height: 3rem;
  }
  .textarea[type='1'] {
    min-width: 32.8rem;
    max-width: 32.8rem;
  }
  .error {
  }
  .block {
  }

  .textAreaField.hasError {
    .label {
    }
    .textarea {
      div {
      }
    }
    .textarea::placeholder {
    }
  }
}

@import '../../styles/variables.module.scss';
.listWithRadioButtons {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.radioOption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.radioInput {
  appearance: none;
}
.radioLabel {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 0.7rem;
  align-items: center;
  cursor: pointer;
  text-align: start;
  @include label();
  .listWithRadioButtons__radioButton {
    width: 2.4rem;
  }
}
.deleteButton {
  width: 1.6rem;
  align-self: flex-start;
}


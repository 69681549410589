@import '../../styles/variables.module.scss';

.section {
  width: 128rem;
  margin: 0 auto;

  .block {
    width: 100%;
    padding-top: 1.6rem;

    .navigation {
      @include breadcrumb();
      text-align: start;
      margin-bottom: 1.4rem;
    }
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .section {
    width: 70.6rem;
    .block {
      padding-top: 2.4rem;
      .navigation {
        margin-bottom: 2.4rem;
      }
    }
  }
}
@media (max-width: 767px) {
  .section {
    width: 32.8rem;
    .block {
      position: relative;
      padding-top: 1.6rem;
      .navigation {
        margin-bottom: 1.6rem;
      }
    }
  }
}

@import '../../styles/variables.module.scss';

.dropdown {
  text-align: start;
}
.dropdownRightBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include headline-9();
  text-transform: uppercase;
  background-color: inherit;
}
.dropdownLeftBtn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  @include headline-8();
  text-transform: uppercase;
  background-color: inherit;
}
.chevron{
  width: 2.4rem;
}
ul.dropdownList {
  list-style-type: circle;
}
.description {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  @include body-3();
  margin-top: 0.8rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
}
.show {
  margin-top: 0.8rem;
  max-height: 300px;
}
.icon {
  font-size: 2.4rem;
}
.dropdownText {
  text-align: start;
}
@media (max-width: 950px) {
  .dropdownText {
    @include headline-9();
  }
}
